@tailwind base;
@tailwind components;

:root {
  --containerWidth: 1280px;
}

@responsive {
  .text-shadow {
    text-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  }

  .text-shadow-md {
    text-shadow: 0 4px 8px rgba(0, 0, 0, 0.12), 0 2px 4px rgba(0, 0, 0, 0.08);
  }

  .text-shadow-lg {
    text-shadow: 0 15px 30px rgba(0, 0, 0, 0.11), 0 5px 15px rgba(0, 0, 0, 0.08);
  }

  .text-shadow-none {
    text-shadow: none;
  }
}

@layer base {
  html {
    font-size: 16px;
  }

  body {
    overscroll-behavior-y: none;
    /* @apply font-body; */
  }

  body::selection {
    @apply text-white;
    background-color: var(--ministryColor, var(--color-blue-100));
  }

  body::-moz-selection {
    @apply text-white;
    background-color: var(--ministryColor, var(--color-blue-100));
  }

  body::-webkit-scrollbar-track {
    background: transparent;
    background-color: var(--ministryColor, var(--color-blue-800));
  }

  body::-webkit-scrollbar {
    width: 1px;
    @apply bg-gray-100;
  }

  body::-webkit-scrollbar-thumb {
    @apply text-white;
    background-color: var(--ministryColor, var(--color-blue-100));
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    @apply font-bold mb-4 text-black;
  }

  h1 {
    @apply text-4xl lg:text-5xl;
  }
  h2 {
    @apply text-3xl lg:text-4xl;
  }
  h3 {
    @apply text-2xl lg:text-3xl;
  }
  h4 {
    @apply text-xl lg:text-2xl;
  }
  h5 {
    @apply text-lg lg:text-lg;
  }
  h6 {
    @apply text-lg;
  }

  p {
    @apply mb-4;

    &:last-child {
      @apply mb-0;
    }
  }

  .pb-16x9 {
    padding-bottom: 56.25%;
  }

  .button {
    @apply inline-block bg-blue-500 text-white px-6 py-3 hover:bg-blue-300 font-bold;
  }

  .container {
    @screen xl {
      max-width: var(--containerWidth) !important;
    }
  }

  .ais-SearchBox-form {
    @apply flex items-stretch;
  }

  .ais-SearchBox-input {
    @apply bg-gray-100 text-sm py-2 px-3 rounded-md focus:outline-none focus:bg-gray-200 placeholder-blue-500 font-medium appearance-none;
  }

  .ais-SearchBox-input:focus {
    -webkit-appearance: none;
  }

  .ais-SearchBox-submit {
    @apply bg-lightBlue-500 text-white px-3 py-3 rounded-md text-xs -ml-2 text-white;
  }

  .ais-SearchBox-submit svg {
    fill: white;
  }

  .ais-Highlight-highlighted {
    @apply bg-blue-50 bg-opacity-50;
  }
}

@tailwind utilities;
